import { RootStore } from 'src/features/Store/RootStore.model'
import { readFile } from './readFile'

export async function handleOpenFile (store: RootStore, acceptedFiles: File[]) {
  try {
    const content = await readFile(acceptedFiles)
    if (content.type === 'pdf') {
      store.loadNewFile(content.value)
    } else {
      const snapshot = JSON.parse(content.value)
      console.log('snapshot:', snapshot)
      store.loadSavedFile(snapshot)
    }
  } catch (error) {
    console.warn('unable to open file!', error)
    store.ui.setError('FileError')
  }
}

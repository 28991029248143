import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components/macro'
import { PdfPreviewCanvas } from './PdfPreviewCanvas'
import { usePdfDoc } from '../usePdfDoc'
import { usePdfPage } from '../usePdfPage'
import { Size } from 'src/utils/commonTypes'

const PdfPage = styled(PdfPreviewCanvas)`
  margin-top: ${props => props.theme.spacing(1)}px;
`

type Props = {
  previewFile: Uint8Array,
  scale: number,
  viewportSize: Size | null,
  className?: string
}

export function PdfPreview ({
  previewFile,
  scale,
  viewportSize,
  className
}: Props) {
  const { error: docError, pdfDoc } = usePdfDoc(previewFile) // eslint-disable-line @typescript-eslint/no-unused-vars
  const { error: pageError, pdfPage: firstPage } = usePdfPage(pdfDoc, 1) // eslint-disable-line @typescript-eslint/no-unused-vars

  if (pdfDoc && firstPage && viewportSize) {
    const full = firstPage.getViewport({ scale: 1.0 })
    const fitWidthScale = viewportSize.width / (full.width + 128)
    const fitHeightScale = viewportSize.height / (full.height + 128)
    const fitScale = R.min(fitWidthScale, fitHeightScale)

    return (
      <>
        {
          R.range(1, pdfDoc.numPages + 1).map(pageNum => (
            <PdfPage
              key={pageNum}
              pdfDoc={pdfDoc}
              pageNum={pageNum}
              scale={fitScale * scale}
              className={className}
            />
          ))
        }
      </>
    )
  } else {
    return null
  }
}

import React, { useEffect, useRef } from 'react'
import { PDFDocumentProxy } from '../usePdfDoc'
import { usePdfPage, PDFPageProxy } from '../usePdfPage'
import { renderToCanvas } from '../renderToCanvas'
import { clearCanvas } from '../clearCanvas'
import { useLatestJob } from 'src/utils/hooks/useLatestJob'
import { useDebounce } from 'ahooks'

type Props = {
  pdfDoc: PDFDocumentProxy,
  scale: number,
  pageNum: number,
  className?: string
}

type RenderPageJob = {
  pdfPage: PDFPageProxy | null,
  scale: number
}

export function PdfPreviewCanvas ({
  pdfDoc,
  scale,
  pageNum,
  className
}: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const { error, pdfPage } = usePdfPage(pdfDoc, pageNum) // eslint-disable-line @typescript-eslint/no-unused-vars

  const renderPage = async (job: RenderPageJob) => {
    if (job.pdfPage) {
      await renderToCanvas(canvasRef, job.pdfPage, job.scale)
    } else {
      clearCanvas(canvasRef)
    }
  }

  const { addJob } = useLatestJob<RenderPageJob>(renderPage)

  const debouncedScale = useDebounce(scale, {
    wait: 500
  })

  useEffect(() => {
    addJob({
      pdfPage,
      scale: debouncedScale
    })
  }, [addJob, pdfPage, debouncedScale])

  return (
    <canvas ref={canvasRef} className={className} />
  )
}

import React, { ReactNode, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components/macro'
import BackdropBase from '@material-ui/core/Backdrop'
import { handleOpenFile } from './handleFileOpen'
import { useStore } from 'src/features/Store/StoreContext'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

type Props = {
  children: ReactNode,
  className?: string
}

const Backdrop = styled(BackdropBase)`
  z-index: ${props => props.theme.zIndex.modal};
`

export const FileDropzone = observer(function FileDropzone ({
  children,
  className
}: Props) {
  const store = useStore()

  const onDrop = useCallback((files: File[]) => {
    handleOpenFile(store, files)
  }, [store])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled: store.ui.isAnyModalActive
  })

  useEffect(() => {
    const dispose = reaction(() => store.ui.isLoadModalOpen, (value) => {
      if (value) {
        console.log('open file modal...')
        open()
      }
    })
    return dispose
  }, [store]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div {...getRootProps({ className })}>
      <input {...getInputProps()} />
      {
        children
      }
      <Backdrop open={isDragActive} />
    </div>
  )
})

import { makeAutoObservable } from 'mobx'

export type Snapshot = number

type GetSnapshot = () => Snapshot
type LoadSnapshot = (state: Snapshot) => void

export class Rotation {
  angle!: number

  constructor (initialState: Snapshot) {
    this.loadSnapshot(initialState)
    makeAutoObservable(this)
  }

  loadSnapshot: LoadSnapshot = state => {
    this.angle = state
  }

  getSnapshot: GetSnapshot = () => this.angle

  rotateLeft = () => {
    this.angle = (this.angle + 270) % 360
  }

  rotateRight = () => {
    this.angle = (this.angle + 90) % 360
  }
}

import React from 'react'
import { useStore } from 'src/features/Store/StoreContext'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { observer } from 'mobx-react-lite'

export const MarkModeSwitch = observer(function MarkModeSwitch () {
  const store = useStore()

  return (
    <FormControlLabel
      control={
        <Switch
          checked={store.isTestMode}
          onChange={event => store.setTestMode(event.target.checked)}
          color='primary'
        />
        }
      label='Test mode'
    />
  )
})

import { makeAutoObservable } from 'mobx'

export interface Snapshot {
  fontSize: number,
  color: string,
  template: string,
  baseIndex: number
}

type GetSnapshot = () => Snapshot
type LoadSnapshot = (state: Snapshot) => void

export class MarkSettings {
  fontSize!: number
  color!: string
  template!: string
  baseIndex!: number

  loadSnapshot: LoadSnapshot = state => {
    this.fontSize = state.fontSize
    this.color = state.color
    this.template = state.template
    this.baseIndex = state.baseIndex
  }

  getSnapshot: GetSnapshot = () => ({
    fontSize: this.fontSize,
    color: this.color,
    template: this.template,
    baseIndex: this.baseIndex
  })

  constructor (initialState: Snapshot) {
    this.loadSnapshot(initialState)
    makeAutoObservable(this)
  }

  applyTemplate = (n: number) => {
    return applyTemplate(this.template, n)
  }
}

export const applyTemplate = (template: string, n: number) => template.replace('$idx', n.toString())

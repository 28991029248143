import * as R from 'ramda'

export type Point2d = [number, number]

const { cos, sin, abs, PI } = Math

/**
 * clamps a value between (min, max) limits using modulo
 * @param min - range minimum
 * @param max - range maximum
 * @param value - value to clamp
 * @returns value clamped between (min, max) using modulo
 * @example
 *    moduloClamp(0, 360, -90) -> 270
 *    moduloClamp(10, 20, 36) -> 16
 */
export const moduloClamp = R.curry((min: number, max: number, value: number) => {
  const range = max - min
  return min + ((value + range) % range)
})

/**
 * Transforms an angle from degrees to radians
 * @param deg - angle in degrees
 * @returns angle in radians
 */
export const deg2Rad = (deg: number): number => PI * deg / 180

/**
 * Perform rotation transform on a 2d point
 * @param position - point in 2d space
 * @param angle - angle in radians (clockwise)
 * @returns transformed point
 */
export const rotatePoint = R.curry((angle: number, [x, y]: Point2d): Point2d => {
  return [
    (x * cos(angle)) + (y * sin(angle)),
    (y * cos(angle)) - (x * sin(angle))
  ]
})

/**
 * Rotates a 2d point within a rectangle with given dimensions
 * @param angle - angle in radians
 * @param dims - [width, height] dimensions of the container
 * @param pos - [x, y] position
 * @return transformed position
 */
export const rotateWithinDims = R.curry((
  angle: number,
  dims: Point2d,
  pos: Point2d
): Point2d => {
  const [
    [x, y],
    [width, height]
  ] = R.map(
    (point) => rotatePoint(angle, point),
    [pos, dims]
  )

  return [
    moduloClamp(0, abs(width), x), // transform negative x as coming from the opposite side of the container
    moduloClamp(0, abs(height), y) // transform negative y as coming from the opposite side of the container
  ]
})

/**
 * Transform position by [left, top]
 * @param shift2d - [left, top] shift in position
 * @param pos - [x, y] position
 * @return transformed position
 */
export const shift = R.curry(([left, top]: Point2d, [x, y]: Point2d): Point2d => {
  return [
    x - left,
    y - top
  ]
})

/**
 * Remove scale from a 2d point
 * @param scale - scale amount
 * @param pos - [x, y] position
 * @return transformed position
 */
export const descale = R.curry((scale: number, [x, y]: Point2d): Point2d => {
  return [
    x / scale,
    y / scale
  ]
})

/**
 * Translate a 2d-point from top-left axis to bottom-right axis
 * @param containerDims - [width, height] of the destination container
 * @param pos - position to translate
 * @returns translated point
 * @example
 *    toBottomLeftAxis([16,9], [0,0]) -> [0, 9]
 *    toBottomLeftAxis([16,9], [4,3]) -> [4, 13]
 */
export const toBottomLeftAxis = R.curry((
  containerDims: Point2d,
  [x, y]: Point2d
): Point2d => {
  const [width, height] = containerDims // eslint-disable-line @typescript-eslint/no-unused-vars
  return [
    x,
    height - y
  ]
})

/**
 * Clamps a point inside a container using a given clamp function
 * @param clampFunc - a function that clamps a value between (min, max) range
 * @param containerDims - container dimensions
 * @param point - 2d point to clamp
 * @returns clamped point
 * @example
 *    normalizePoint(R.clamp, [16,9], [-2, 15]) -> [0, 9]
 *    normalizePoint(moduloClamp, [16,9], [-2, 15]) -> [14, 6]
 */
export const clampPoint = R.curry((
  clampFunc: (min: number, max: number, val: number) => number,
  [width, height]: Point2d,
  [x, y]: Point2d
): Point2d => {
  return [
    clampFunc(0, width, x),
    clampFunc(0, height, y)
  ]
})

import React from 'react'
import { observer } from 'mobx-react-lite'
import MenuItem from '@material-ui/core/MenuItem'
import { useStore } from 'src/features/Store/StoreContext'
import { Menu } from './Menu'

export const MainMenu = observer(function MainMenu () {
  const store = useStore()

  return (
    <Menu>
      <MenuItem
        onClick={store.ui.openFileModal}
      >
        Open
      </MenuItem>
      <MenuItem
        disabled={store.document === null}
        onClick={store.ui.openSaveModal}
      >
        Save
      </MenuItem>
      <MenuItem
        disabled={store.document === null}
        onClick={store.exportPdf}
      >
        Export PDF
      </MenuItem>
    </Menu>
  )
})

import React, { ReactNode, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MenuBase from '@material-ui/core/Menu'
import { PopoverOrigin } from '@material-ui/core'

type Props = {
  children: ReactNode
}

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
}

export function Menu ({
  children
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label='menu'
        aria-haspopup='true'
        onClick={handleMenuClick}
      >
        <MenuIcon />
      </IconButton>
      <MenuBase
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        onClick={closeMenu}
      >
        {children}
      </MenuBase>
    </>
  )
}

import { makeAutoObservable } from 'mobx'

export interface Snapshot {
  file: Uint8Array,
  scale: number
}

type GetSnapshot = () => Snapshot
type LoadSnapshot = (state: Snapshot) => void

const scaleDelta = 0.4

export class PdfExport {
  file!: Uint8Array
  scale!: number

  loadSnapshot: LoadSnapshot = state => {
    this.file = state.file
    this.scale = state.scale
  }

  getSnapshot: GetSnapshot = () => ({
    file: this.file,
    scale: this.scale
  })

  constructor (initialState: Snapshot) {
    this.loadSnapshot(initialState)
    makeAutoObservable(this)
  }

  zoomIn = () => {
    const newScale = this.scale + scaleDelta
    if (newScale < 8.0) {
      this.scale = newScale
    }
  }

  zoomOut = () => {
    const newScale = this.scale - scaleDelta
    if (newScale > 0.5) {
      this.scale = newScale
    }
  }

  zoomReset = () => {
    this.scale = 1.0
  }
}

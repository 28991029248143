import React from 'react'
import ReactDOM from 'react-dom'
import App from './features/App'
import { configure } from 'mobx'
import { StoreProvider } from './features/Store/StoreContext'
import { RootStore } from './features/Store/RootStore.model'
import { ThemeProvider } from 'src/features/Theme'
import 'fontsource-roboto'

configure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  // reactionRequiresObservable: true, // turned off to stop warnings about MarksList created/updated without reading any observable state when it actually does
  observableRequiresReaction: true,
  disableErrorBoundaries: true
})

let store = new RootStore({
  file: null,
  document: null,
  scale: 50
})

function renderApp () {
  ReactDOM.render(
    <ThemeProvider>
      <StoreProvider value={store}>
        <App />
      </StoreProvider>
    </ThemeProvider>,
    document.getElementById('root')
  )
}

if (module.hot) {
  module.hot.accept(['./features/App'], () => {
    renderApp()
  })
  module.hot.accept(['./features/Store/RootStore.model'], () => {
    const snapshot = store.getSnapshot()
    console.log('reload new store from existing snapshot:', snapshot)
    store = new RootStore(snapshot) // try create updated store from the existing snapshot
    renderApp()
  })
}

renderApp()

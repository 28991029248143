export type OverlayItemProps = {
  x: number,
  y: number,
  size: number,
  rotation: number,
  text?: string
}

export type GeneralOverlayItem = (props: OverlayItemProps) => JSX.Element

export const getX = ({ x }: OverlayItemProps) => x
export const getY = ({ y }: OverlayItemProps) => y
export const getSize = ({ size }: OverlayItemProps) => size
export const getCenterHorizontal = ({ x, size }: OverlayItemProps) => x - (size / 2)
export const getCenterVertical = ({ y, size }: OverlayItemProps) => y - (size / 2)
export const getRotation = ({ rotation }: OverlayItemProps) => rotation

import React, { ReactNode } from 'react'
import {
  ThemeProvider as StyledComponentsThemeProvider
} from 'styled-components/macro'
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
  CssBaseline
} from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

export const theme = createMuiTheme({
  palette: {
    //  https://material-ui.com/customization/palette/
    type: 'dark',
    primary: {
      main: blue.A200
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
})

type Props = {
  children: ReactNode,
}

export function ThemeProvider ({
  children
}: Props) {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledComponentsThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import AddIcon from '@material-ui/icons/Add'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import { useStore } from 'src/features/Store/StoreContext'
import { MarkSettingsForm } from 'src/features/Marks/MarkSettingsForm'

type Props = {
  active?: boolean,
  onClick: () => void,
}

export const AddMarkingButton = observer(function AddMarkingButton ({
  active,
  onClick
}: Props) {
  const anchorRef = useRef<HTMLDivElement>(null)
  const store = useStore()
  const [open, setOpen] = useState<boolean>(false)

  const handleToggle = () => setOpen(!open)
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const settings = store.document && (
    <MarkSettingsForm document={store.document} />
  )

  return (
    <>
      <Tooltip title='Add labels'>
        <ButtonGroup
          variant='contained'
          color={active ? 'primary' : undefined}
          ref={anchorRef}
          aria-label='split button'
          disabled={store.document === null}
          onClick={onClick}
        >
          <Button>
            <AddIcon />
            <Typography>
              {
                store.document?.nextIndexString
              }
            </Typography>
          </Button>
          <Button
            size='small'
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label='select merge strategy'
            aria-haspopup='menu'
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <div>
              <ClickAwayListener onClickAway={handleClose} mouseEvent='onMouseDown'>
                <Paper>
                  {
                    settings
                  }
                </Paper>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  )
})

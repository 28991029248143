type FileType = 'pdf' | 'save'

type FileContent = {
  type: FileType,
  value: string
}

export function readFile (acceptedFiles: File[]) {
  return new Promise<FileContent>((resolve, reject) => {
    const reader = new window.FileReader()
    const file = acceptedFiles[0]

    reader.onabort = reject
    reader.onerror = reject

    switch (file.type) {
      case 'application/pdf':
        reader.onload = () => resolve({
          type: 'pdf',
          value: reader.result as string
        })
        reader.readAsDataURL(file)
        break
      default:
        reader.onload = () => resolve({
          type: 'save',
          value: reader.result as string
        })
        reader.readAsText(file)
    }
  })
}

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../Store/StoreContext'
import IconButton from '@material-ui/core/IconButton'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import RotateRightIcon from '@material-ui/icons/RotateRight'

export const Rotation = observer(function Rotation () {
  const store = useStore()
  const isDisabled = store.document === null

  return (
    <>
      <IconButton
        onClick={store.document?.currentPage.rotation.rotateLeft}
        disabled={isDisabled}
      >
        <RotateLeftIcon />
      </IconButton>
      <IconButton
        onClick={store.document?.currentPage.rotation.rotateRight}
        disabled={isDisabled}
      >
        <RotateRightIcon />
      </IconButton>
    </>
  )
})

import * as R from 'ramda'
import { Point2d, rotateWithinDims, shift, descale, deg2Rad } from 'src/utils/Point2d'

type Rect = {
  left: number
  top: number
  width: number
  height: number
}

/**
 * Transform a mouse event position over a rotated, scaled and shifted target area
 * @param pos - mouse event position
 * @param rect - container bounding rect
 * @param rotation - rotation angle in degrees (clockwise)
 * @param scale - scale factor
 * @returns transformed position
 */
export const transformPos = (
  pos: Point2d,
  rect: Rect,
  rotation: number,
  scale: number
): Point2d => {
  const transform = R.pipe(
    shift([rect.left, rect.top]),
    rotateWithinDims(deg2Rad(rotation), [rect.width, rect.height]),
    descale(scale)
  ) as (pos: Point2d) => Point2d
  return transform(pos)
}

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import DownloadIcon from '@material-ui/icons/GetApp'
import { PdfExport } from 'src/features/Store/PDF/PdfExport'
import { PdfPreview } from 'src/features/PdfRenderer/PdfPreview'
import { exportPdf } from '../File/ExportPdf/exportPdf'
import { Viewport } from './Viewport'
import { Size } from 'src/utils/commonTypes'

const Stage = styled.div`
  margin: auto;
  border: 9px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
`

type Props = {
  open: boolean,
  pdfExport: PdfExport | null,
  onClose: () => void
}

export const PreviewModal = observer(function PreviewModal ({
  open,
  pdfExport,
  onClose
}: Props) {
  const [size, setSize] = useState<Size | null>(null)

  const download = () => {
    if (pdfExport) {
      exportPdf(pdfExport.file)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle>
        Export Preview
      </DialogTitle>

      <DialogContent>
        <Viewport
          onZoomIn={pdfExport?.zoomIn}
          onZoomOut={pdfExport?.zoomOut}
          onZoomReset={pdfExport?.zoomReset}
          onSizeChange={setSize}
        >
          {
            pdfExport === null
              ? 'loading...'
              : (
                <Stage>
                  <PdfPreview
                    previewFile={pdfExport.file}
                    scale={pdfExport.scale}
                    viewportSize={size}
                  />
                </Stage>
                )
          }
        </Viewport>
      </DialogContent>
      <DialogActions>
        <Tooltip placement='top' title='Download'>
          <Button
            variant='contained'
            color='primary'
            onClick={download}
          >
            <DownloadIcon />
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
})

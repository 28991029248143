import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import PrevIcon from '@material-ui/icons/KeyboardArrowLeft'
import NextIcon from '@material-ui/icons/KeyboardArrowRight'
import { observer } from 'mobx-react-lite'
import { useStore } from '../Store/StoreContext'

export const PageNav = observer(function PageNav () {
  const store = useStore()
  const isDisabled = store.document === null

  return (
    <>
      <IconButton
        onClick={store.document?.prevPage}
        disabled={isDisabled || store.document?.isFirstPage}
      >
        <PrevIcon />
      </IconButton>
      <span>
        {
          isDisabled
            ? ''
            : `${store.document?.currentPageIdx} / ${store.document?.totalPages}`
        }
      </span>
      <IconButton
        onClick={store.document?.nextPage}
        disabled={isDisabled || store.document?.isLastPage}
      >
        <NextIcon />
      </IconButton>
    </>
  )
})

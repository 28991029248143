import { makeAutoObservable } from 'mobx'
import { Rotation, Snapshot as RotationSnapshot } from 'src/features/Rotation/Rotation.model'

export interface Snapshot {
  rotation: RotationSnapshot
}

type GetSnapshot = () => Snapshot
type LoadSnapshot = (state: Snapshot) => void

export class PdfPage {
  rotation!: Rotation

  loadSnapshot: LoadSnapshot = state => {
    this.rotation.loadSnapshot(state.rotation)
  }

  getSnapshot: GetSnapshot = () => ({
    rotation: this.rotation.getSnapshot()
  })

  constructor (initialState: Snapshot) {
    this.rotation = new Rotation(initialState.rotation)
    this.loadSnapshot(initialState)
    makeAutoObservable(this, {
      rotation: false
    })
  }
}

import { Rect } from './Rect'
import styled, { css } from 'styled-components/macro'

export type { RectProps } from './Rect'

type Props = {
  active?: boolean
}

const activeStyle = css`
  opacity: 1;
  outline: 2px solid currentColor;
`

export const Area = styled(Rect)`
  border: 1px solid currentColor;
  opacity: 0.8;

  &:hover {
    outline: 1px solid currentColor;
    opacity: 1;
  }

  &:focus {
    ${activeStyle}
  }

  ${(props: Props) => props.active && activeStyle}
`

import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { index } from 'src/utils/list/indexItems'
import { PdfPage, Snapshot as PdfPageSnapshot } from './PdfPage.model'
import { Area, Snapshot as AreaSnapshot } from 'src/features/Areas/Area.model'
import { MarkSettings, Snapshot as MarkSettingsSnapshot } from 'src/features/Marks/MarkSettings.model'

type Rect = {
  left: number,
  top: number,
  width: number,
  height: number
}

export interface Snapshot {
  currentPage: number
  areas: AreaSnapshot[]
  pages: PdfPageSnapshot[]
  markSettings: MarkSettingsSnapshot
}

const expose = (name: string, value: any) => {
  (window as any)[name] = value
}

type GetSnapshot = () => Snapshot
type LoadSnapshot = (state: Snapshot) => void

export class PdfDocument {
  currentPageIdx!: number
  pages!: PdfPage[]
  areas!: Area[]
  markSettings!: MarkSettings

  loadSnapshot: LoadSnapshot = state => {
    this.currentPageIdx = state.currentPage
    this.areas = state.areas.map(areaSnapshot => new Area(areaSnapshot))
    this.pages = state.pages.map(page => new PdfPage(page))
    this.markSettings = new MarkSettings(state.markSettings)
  }

  getSnapshot: GetSnapshot = () => ({
    currentPage: this.currentPageIdx,
    areas: this.areas.map(area => area.getSnapshot()),
    pages: this.pages.map(page => page.getSnapshot()),
    markSettings: this.markSettings.getSnapshot()
  })

  constructor (initialState: Snapshot) {
    this.loadSnapshot(initialState)
    console.log('doc this:', this)

    makeAutoObservable(this)
    expose('doc', this)
  }

  get totalPages (): number {
    return this.pages.length
  }

  get currentPage (): PdfPage {
    return this.pages[this.currentPageIdx - 1]
  }

  get isFirstPage (): boolean {
    return this.currentPageIdx === 1
  }

  get isLastPage (): boolean {
    return this.currentPageIdx === this.totalPages
  }

  get indexedAreas () {
    return index(this.areas)
  }

  get indexedAreasOnCurrentPage () {
    return this.indexedAreas.filter(([, area]) => area.page === this.currentPageIdx)
  }

  get nextIndexString () {
    const { applyTemplate, baseIndex } = this.markSettings
    return applyTemplate(baseIndex + this.areas.length)
  }

  nextPage = () => {
    if (this.isLastPage) {
      return
    }
    this.currentPageIdx += 1
  }

  prevPage = () => {
    if (this.isFirstPage) {
      return
    }
    this.currentPageIdx -= 1
  }

  addArea = ({ left, top, width, height }: Rect) => {
    this.areas.push(new Area({
      id: nanoid(),
      page: this.currentPageIdx,
      x: left,
      y: top,
      width,
      height
    }))
  }

  removeArea = (id: string) => {
    const updatedAreas = this.areas.filter(area => area.id !== id)
    this.areas = updatedAreas
  }
}

// export const createPdfDocument = (initialState: Snapshot) => new PdfDocument(initialState)

import { makeAutoObservable, flow } from 'mobx'
import * as R from 'ramda'
import { sleep } from 'src/utils/sleep'
import { PdfDocument, Snapshot as DocumentSnapshot } from './PDF/PdfDocument.model'
import { UiStore } from './UI/UiStore.model'
import { renderChanges } from 'src/features/File/ExportPdf/renderChanges'
import { PdfExport } from './PDF/PdfExport'

export interface Snapshot {
  file: string | null
  document: DocumentSnapshot | null
  scale: number
  isTestMode?: boolean
}

type GetSnapshot = () => Snapshot
type LoadSnapshot = (state: Snapshot) => void

export class RootStore {
  file!: string | null
  document!: PdfDocument | null
  scale!: number
  ui!: UiStore
  exportData!: PdfExport | null
  isTestMode!: boolean

  loadSnapshot: LoadSnapshot = state => {
    this.file = state.file
    this.scale = state.scale
    if (state.document) {
      this.document = new PdfDocument(state.document)
    } else {
      this.document = null
    }
    this.exportData = null
    this.isTestMode = state.isTestMode ?? false
  }

  getSnapshot: GetSnapshot = () => ({
    file: this.file,
    document: this.document?.getSnapshot() ?? null,
    scale: this.scale,
    isTestMode: this.isTestMode
  })

  constructor (initialState: Snapshot) {
    this.ui = new UiStore(errorType => {
      if (errorType === 'FileError') {
        this.document = null
      }
    })
    this.loadSnapshot(initialState)
    makeAutoObservable(this, {
      loadNewFile: false,
      loadSavedFile: false,
      exportPdf: false,
      ui: false // uiStore never changes so no need to track it
    })
  }

  loadNewFile = flow(function * (this: RootStore, content: string) {
    this.file = null
    this.document = null
    yield sleep()
    this.file = content
  }).bind(this)

  loadSavedFile = flow(function * (this: RootStore, state: Snapshot) {
    this.file = null
    this.document = null
    yield sleep()
    this.loadSnapshot(state)
  }).bind(this)

  createDocument = (totalPages: number) => {
    const pages = R.repeat({
      rotation: 0
    }, totalPages)

    this.document = this.document || new PdfDocument({
      pages,
      currentPage: 1,
      areas: [],
      markSettings: {
        fontSize: 16,
        color: '#aa2e25',
        template: '{$idx}',
        baseIndex: 2
      }
    })
  }

  setScale = (scale: number) => {
    this.scale = scale
  }

  exportPdf = flow(function * (this: RootStore) {
    this.exportData = null
    this.ui.openExportModal()
    if (this.file && this.document) {
      const pdfData: Uint8Array = yield renderChanges(this.file, this.document.getSnapshot(), this.isTestMode)
      console.log('pdfData length:', pdfData.length)
      this.exportData = new PdfExport({
        file: pdfData,
        scale: 1.0
      })
    }
  }).bind(this)

  setTestMode = (value: boolean) => {
    this.isTestMode = value
  }
}

import React from 'react'
import styled from 'styled-components/macro'

const AbsoluteDiv = styled.div`
  position: absolute;
`

export type RectProps = {
  left: number,
  top: number,
  width: number,
  height: number
}

type Props = RectProps & ComponentProps<typeof AbsoluteDiv>

export const Rect = ({
  left,
  top,
  width,
  height,
  ...restProps
}: Props) => {
  return (
    <AbsoluteDiv
      style={{
        left,
        top,
        width,
        height
      }}
      {...restProps}
    />
  )
}

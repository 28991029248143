import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

type Props = {
  error: string | null,
  onClose: () => void
}

export function AlertDialog ({
  error,
  onClose
}: Props) {
  return (
    <Dialog
      open={!!error}
      onClose={onClose}
      disableBackdropClick
    >
      <DialogTitle>
        {error}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color='primary'>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

import React, { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import { PdfDocument } from 'src/features/Store/PDF/PdfDocument.model'
import { transformPos } from './transformPos'
import { useDebounceFn } from 'ahooks'
import { AreaList } from 'src/features/Areas/AreaList'
import { AreaSelect } from 'src/features/Areas/AreaSelect'

type Point2d = [number, number]

const MarksContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: crosshair;
  user-select: none;
`

type Props = {
  document: PdfDocument
  scale: number
}

export const MarksOverlay = observer(function MarksOverlay ({
  document,
  scale
}: Props) {
  const overlayRef = useRef(null)
  const [start, setStart] = useState<Point2d>()
  const [end, setEnd] = useState<Point2d>()

  const rotation = document.currentPage.rotation.angle

  const calc = (start?: Point2d, end?: Point2d) => {
    if (start && end) {
      const [x0, y0] = start
      const [x1, y1] = end
      const [top, bottom] = R.sort(R.subtract, [y0, y1])
      const [left, right] = R.sort(R.subtract, [x0, x1])
      // console.log('[top, bottom]', [top, bottom])
      // console.log('[left, right]', [left, right])
      const width = right - left
      const height = bottom - top
      return {
        top,
        left,
        width,
        height
      }
    } else {
      return null
    }
  }

  const foo = (pos: Point2d) => {
    const overlayEl = overlayRef.current
    if (overlayEl) {
      const rect = (overlayEl as HTMLElement).getBoundingClientRect()
      const res = transformPos(pos, rect, rotation, scale)
      // console.log(`${pos} => ${res}`)
      return res
    }
  }

  const onSelectStart = (event: React.MouseEvent) => {
    if (event.buttons !== 1) { // only the left mouse button
      return
    }
    const { clientX, clientY } = event.nativeEvent
    setStart(foo([clientX, clientY]))
    setEnd(undefined)
  }

  const onSelectMove = (event: React.MouseEvent) => {
    const { clientX, clientY } = event.nativeEvent
    setEnd(foo([clientX, clientY]))
  }

  const onSelectDone = () => {
    setStart(undefined)
    const rect = calc(start, end)
    if (end && rect) {
      document.addArea(rect)
    }
  }

  const { run } = useDebounceFn(onSelectMove, {
    wait: 2
  })

  const res = calc(start, end)

  // console.log('res:', res)

  return (
    <MarksContainer
      ref={overlayRef}
      onMouseDown={onSelectStart}
      onMouseMove={start && run}
      onMouseUp={onSelectDone}
      onContextMenu={event => event.preventDefault()}
    >
      {
        res
          ? (
            <AreaSelect
              top={res.top * scale}
              left={res.left * scale}
              width={res.width * scale}
              height={res.height * scale}
            />
            )
          : null
      }
      {/* <MarkList
        marks={document.indexedMarksOnCurrentPage}
        scale={scale}
        rotation={-document.currentPage.rotation.angle}
      /> */}
      <AreaList
        areas={document.indexedAreasOnCurrentPage}
        scale={scale}
        rotation={-rotation}
        markSettings={document.markSettings}
        onRemoveArea={document.removeArea}
      />
    </MarksContainer>
  )
})

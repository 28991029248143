import { saveAs } from 'file-saver'
import { RootStore } from 'src/features/Store/RootStore.model'

export const saveState = (store: RootStore, filename: string) => {
  const snapshot = store.getSnapshot()
  console.log('state:', snapshot)
  // some browsers (FireFox) ignore the given extension in the filename and assign one according to the mime type, thus the 'octet/stream' is used.
  // TODO - save the file in compressed (gzip?) form
  const file = new File([JSON.stringify(snapshot)], filename, { type: 'octet/stream' })
  console.log('file:', file)
  saveAs(file)
}

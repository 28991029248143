import { makeAutoObservable } from 'mobx'

export interface Snapshot {
  id: string,
  page: number,
  x: number,
  y: number,
  width: number,
  height: number
}

type GetSnapshot = () => Snapshot
type LoadSnapshot = (state: Snapshot) => void

export class Area {
  id!: string
  page!: number
  x!: number
  y!: number
  width!: number
  height!: number

  loadSnapshot: LoadSnapshot = state => {
    this.id = state.id
    this.page = state.page
    this.x = state.x
    this.y = state.y
    this.width = state.width
    this.height = state.height
  }

  getSnapshot: GetSnapshot = () => ({
    id: this.id,
    page: this.page,
    x: this.x,
    y: this.y,
    width: this.width,
    height: this.height
  })

  constructor (initialState: Snapshot) {
    this.loadSnapshot(initialState)
    makeAutoObservable(this)
  }
}

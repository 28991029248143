import React, { ChangeEvent } from 'react'
import styled from 'styled-components/macro'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import * as R from 'ramda'
import { SliderPicker as ColorPicker, ColorResult } from 'react-color'

import { PdfDocument } from 'src/features/Store/PDF/PdfDocument.model'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(1)}px;

  & > * {
    margin-top: ${props => props.theme.spacing(1)}px;
    margin-bottom: ${props => props.theme.spacing(1)}px;
  }
`

const ColorPickerContainer = styled(Card)`
  padding: 8px;
  border-color: rgba(255, 255, 255, 0.23);

  &:hover {
    border-color: #fff;
    background-color: rgba(255,255,255,0.1);
  }
`

const getChangedValue = (event: ChangeEvent<HTMLInputElement>) => event.target.value

type Props = {
  document: PdfDocument,
}

export const MarkSettingsForm = observer(function MarkSettingsForm ({
  document
}: Props) {
  const handleColorChange = action('update color', (color: ColorResult) => {
    document.markSettings.color = color.hex
  })

  return (
    <Form noValidate autoComplete='off'>
      <TextField
        type='number'
        label='Initial index'
        variant='outlined'
        size='small'
        autoFocus
        value={document.markSettings.baseIndex}
        onChange={
          action('updateBaseIndex', R.pipe(
            getChangedValue,
            parseInt,
            (val: number) => { document.markSettings.baseIndex = val }
          ))
        }
      />
      <TextField
        type='number'
        label='Font size'
        variant='outlined'
        size='small'
        value={document.markSettings.fontSize}
        onChange={
          action('updateFontSize', R.pipe(
            getChangedValue,
            parseInt,
            (val: number) => { document.markSettings.fontSize = val }
          ))
        }
      />
      <TextField
        type='text'
        label='Template'
        variant='outlined'
        size='small'
        value={document.markSettings.template}
        onChange={
          action('updateTemplate', R.pipe(
            getChangedValue,
            (val: string) => { document.markSettings.template = val }
          ))
        }
      />
      <Tooltip title='Color'>
        <ColorPickerContainer variant='outlined'>
          <ColorPicker
            color={document.markSettings.color}
            onChangeComplete={handleColorChange}
          />
        </ColorPickerContainer>
      </Tooltip>

    </Form>
  )
})

import { PDFDocument, degrees } from 'pdf-lib'
import * as R from 'ramda'
import { Snapshot as DocumentSnapshot } from 'src/features/Store/PDF/PdfDocument.model'
import { Point2d, deg2Rad, rotatePoint, clampPoint, toBottomLeftAxis, moduloClamp, shift } from 'src/utils/Point2d'
import { index } from 'src/utils/list/indexItems'
import { applyTemplate } from 'src/features/Marks/MarkSettings.model'
import { rgbFromHex } from './rgbFromHex'

const centerPos = R.curry((
  size: number,
  rotation: number,
  [x, y]: Point2d
) => {
  const rad = deg2Rad(rotation)
  const cos = Math.cos(rad)
  const sin = Math.sin(rad)
  const dx = cos - sin
  const dy = -(cos + sin)
  return [
    x - dx * size / 2,
    y - dy * size / 2
  ] as Point2d
})

const makeTranslatePos = (
  preRotation: number,
  containerDims: Point2d,
  shiftPos: Point2d,
  rotation: number,
  size: number
) => {
  return R.pipe(
    centerPos(size, rotation),
    rotatePoint(deg2Rad(preRotation)),
    clampPoint(moduloClamp, containerDims),
    toBottomLeftAxis(containerDims),
    shift(shiftPos)
  ) as (pos: Point2d) => Point2d
}

const toFixed = (numbers: number[]) => numbers.map(n => n.toFixed())

export const renderChanges = async (srcFile: string, docState: DocumentSnapshot, isTestMode: boolean) => {
  const pdfDoc = await PDFDocument.load(srcFile)

  const { fontSize, color, baseIndex, template } = docState.markSettings

  const pages = pdfDoc.getPages()
  console.log('pages:', pages)

  const indexedMarks = index(docState.areas)

  const marksByPage = R.pipe(
    R.groupBy(([, mark]) => mark.page),
    R.toPairs
  )(indexedMarks) as [string, typeof indexedMarks][]

  console.log('marksByPage:', JSON.stringify(marksByPage, null, 2))

  // add markings
  marksByPage.forEach(([pageStr, marks]) => {
    const pageNum = parseInt(pageStr, 10)
    const page = pages[pageNum - 1]
    const pageState = docState.pages[pageNum - 1]

    console.log('drawing markings for page: ', pageNum)
    const mediaBox = page.getMediaBox()
    const bleedBox = page.getBleedBox()
    const cropBox = page.getCropBox()
    const artBox = page.getArtBox()
    const trimBox = page.getTrimBox()
    const pagePos = page.getPosition()
    const dims = [page.getWidth(), page.getHeight()] as Point2d
    const preRotation = page.getRotation().angle

    console.log('page data:', {
      pagePos,
      dims,
      mediaBox,
      bleedBox,
      cropBox,
      artBox,
      trimBox,
      preRotation
    })

    const { x: shiftX, y: shiftY, width, height } = trimBox

    const translatePos = makeTranslatePos(preRotation, [width, height], [-shiftX, -shiftY], pageState.rotation, fontSize)

    // rotate page
    marks.forEach(([idx, mark]) => {
      const currRotation = docState.pages[mark.page - 1].rotation
      const rotation = preRotation + currRotation
      console.log(`pre ${preRotation} curr ${currRotation} => ${rotation}`)

      const [x, y] = translatePos([mark.x, mark.y])
      console.log(`mark ${mark.id} => ${toFixed([x, y])}`)

      if (isTestMode) {
        page.drawRectangle({
          x,
          y,
          width: fontSize,
          height: fontSize,
          color: rgbFromHex(color),
          rotate: degrees(rotation)
        })
      } else {
        page.drawText(`${applyTemplate(template, baseIndex + idx)}`, {
          x,
          y,
          size: fontSize,
          color: rgbFromHex(color),
          rotate: degrees(rotation)
        })
      }
    })
  })

  // change rotation
  docState.pages.forEach(({ rotation }, idx) => {
    const page = pages[idx]
    const oldRotation = page.getRotation().angle
    page.setRotation(degrees(oldRotation + rotation))
  })

  return pdfDoc.save()
}

import React from 'react'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import { Area, RectProps } from './Area'

type Props = RectProps & {
  children: React.ReactNode,
  className?: string,
  onDelete: () => void,
  color: string,
}

export const AreaItem = ({
  children,
  onDelete,
  ...otherProps
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const closePopover = () => setAnchorEl(undefined)

  return (
    <Area
      onContextMenu={event => {
        setAnchorEl(event.currentTarget)
      }}
      tabIndex={0}
      active={Boolean(anchorEl)}
      {...otherProps}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        onMouseDown={event => event.stopPropagation()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <MenuItem onClick={onDelete}>Delete</MenuItem>
      </Popover>
      {children}
    </Area>
  )
}

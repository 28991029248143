import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'src/features/Store/StoreContext'
import { usePdfDoc } from './usePdfDoc'
import { usePdfPage, PDFPageProxy } from './usePdfPage'
import { renderToCanvas } from './renderToCanvas'
import { clearCanvas } from './clearCanvas'
import { useLatestJob } from 'src/utils/hooks/useLatestJob'

type Props = {
  className?: string
}

type RenderPageJob = {
  pdfPage: PDFPageProxy | null,
  scale: number
}

export const PdfCanvas = observer(function PdfCanvas ({
  className
}: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const store = useStore()

  const file = store.file
  const pageNum = store.document?.currentPageIdx ?? null
  const scale = store.scale / 100

  const { error: docError, pdfDoc } = usePdfDoc(file)
  const { error: pageError, pdfPage } = usePdfPage(pdfDoc, pageNum)

  const renderPage = async (job: RenderPageJob) => {
    if (job.pdfPage) {
      await renderToCanvas(canvasRef, job.pdfPage, job.scale)
    } else {
      clearCanvas(canvasRef)
    }
  }

  const { addJob } = useLatestJob<RenderPageJob>(renderPage)

  useEffect(() => {
    if (docError !== null) {
      console.log('error:', docError)
      store.ui.setError('FileError')
    } else if (pdfDoc) {
      console.log('creating new PDF document')
      store.createDocument(pdfDoc.numPages)
    }
  }, [store, pdfDoc, docError])

  useEffect(() => {
    if (pageError !== null) {
      console.log('error:', pageError)
      store.ui.setError('PageError')
    } else {
      addJob({
        pdfPage,
        scale
      })
    }
  }, [store, pageError, pdfPage, scale, addJob])

  return (
    <canvas ref={canvasRef} className={className} />
  )
})

import React from 'react'
import styled from 'styled-components/macro'
import { GeneralOverlayItem, getSize, getCenterHorizontal, getCenterVertical } from './GeneralOverlayItem'

const Rectangle = styled.div`
  position: absolute;
  width: ${getSize}px;
  height: ${getSize}px;
  background-color: currentColor;
  top: ${getCenterVertical}px;
  left: ${getCenterHorizontal}px;
`

export const MarkTest: GeneralOverlayItem = (props) => {
  const { text, ...otherProps } = props // remove text from otherProps
  return (<Rectangle {...otherProps} />)
}

import React from 'react'
import styled from 'styled-components/macro'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStore } from 'src/features/Store/StoreContext'
import { Indexed } from 'src/utils/list/indexItems'
import { Area } from './Area.model'
import { AreaItem } from './AreaItem'
import { getOverlayItem } from 'src/features/Marks/MarkOverlayItems/getOverlayItem'
import { MarkSettings } from 'src/features/Marks/MarkSettings.model'
import * as R from 'ramda'

type Props = {
  areas: Indexed<Area>[],
  scale: number,
  rotation: number,
  markSettings: MarkSettings,
  onRemoveArea: (id: string) => void
}

const StyledAreaItem = styled(AreaItem)`
  color: ${R.prop('color')};
`

export const AreaList = observer(function AreaList ({
  areas,
  scale,
  rotation,
  markSettings,
  onRemoveArea
} :Props) {
  const store = useStore()
  const OverlayItem = getOverlayItem(store.isTestMode)

  const { fontSize, color, applyTemplate, baseIndex } = markSettings

  return (
    <>
      {
        areas.map(([idx, area]) => (
          <StyledAreaItem
            key={area.id}
            left={area.x * scale}
            top={area.y * scale}
            width={area.width * scale}
            height={area.height * scale}
            color={color}
            onDelete={action('handleAreaDelete', () => onRemoveArea(area.id))}
          >
            <OverlayItem
              x={0}
              y={0}
              size={fontSize * scale}
              rotation={rotation}
              text={`${applyTemplate(baseIndex + idx)}`}
            />
          </StyledAreaItem>
        ))
      }
    </>
  )
})

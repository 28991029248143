import React from 'react'
import styled, { css } from 'styled-components/macro'
import { FileDropzone as FileDropzoneBase } from 'src/features/File/Load/FileDropzone'
import { observer } from 'mobx-react-lite'
import { useStore } from 'src/features/Store/StoreContext'
import { AlertDialog } from 'src/features/UI/AlertDialog'
import { SelectScale } from 'src/features/Scale'
import { PageNav } from 'src/features/PageNav'
import { Rotation } from 'src/features/Rotation'
import { PdfViewport } from 'src/features/PdfViewport'
import { MainMenu } from 'src/features/MainMenu'
import { SaveFileDialog } from 'src/features/File/Save/SaveFileDialog'
import { saveState } from 'src/features/File/Save/saveState'
import { MarksOverlay } from 'src/features/Marks/MarksOverlay'
import { PreviewModal } from 'src/features/ExportPdf/PreviewModal'
import { MarkModeSwitch } from 'src/features/Marks/MarkModeSwitch'
import { EditorMode } from 'src/features/EditorMode/EditorMode'

const absoluteFill = css`
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const FileDropzone = styled(FileDropzoneBase)`
  ${absoluteFill}
`

const Layout = styled.div`
  ${absoluteFill}
  display: grid;
  grid-template-rows: 64px auto;
  grid-template-areas:
    "toolbar"
    "main"
  ;
`

const Toolbar = styled.div`
  grid-area: toolbar;
  background-color: ${props => props.theme.palette.background.default};
  align-items: center;
  display: flex;
`

const Main = styled.main`
  grid-area: main;
  background-color: ${props => props.theme.palette.background.paper};
  overflow: auto;

  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

function App () {
  const store = useStore()

  return (
    <FileDropzone>
      <Layout>
        <Toolbar>
          <MainMenu />
          <PageNav />
          <SelectScale />
          <Rotation />
          <MarkModeSwitch />
          <EditorMode />
        </Toolbar>
        <Main>
          <PdfViewport>
            {
              store.document
                ? (
                  <MarksOverlay document={store.document} scale={store.scale / 100} />
                  )
                : null
            }
          </PdfViewport>
        </Main>
      </Layout>
      <AlertDialog error={store.ui.error} onClose={store.ui.clearError} />
      <SaveFileDialog
        open={store.ui.isSaveModalOpen}
        onSave={filename => {
          store.ui.closeSaveModal()
          saveState(store, filename)
        }}
        onCancel={store.ui.closeSaveModal}
      />
      <PreviewModal
        open={store.ui.isExportModalOpen}
        onClose={store.ui.closeExportModal}
        pdfExport={store.exportData}
      />
    </FileDropzone>
  )
}

export default observer(App)

import React from 'react'
import FabBase from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

type Props = {
  title?: string,
  onClick?: () => void,
  children: JSX.Element
}

export function Fab ({
  title,
  onClick,
  children
}: Props) {
  return (
    <Tooltip placement='left' title={title ?? ''}>
      <FabBase color='primary' onClick={onClick} size='small'>
        {children}
      </FabBase>
    </Tooltip>
  )
}

import { useEffect, useState } from 'react'
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist'

export type { PDFPageProxy }

type ErrorHandler = (errMsg: string) => void

async function processPdfPage (
  pdfDoc: PDFDocumentProxy,
  pageNum: number,
  setPdfPage: (pdfPage: PDFPageProxy | null) => void,
  onError: ErrorHandler
) {
  try {
    const page = await pdfDoc.getPage(pageNum)
    setPdfPage(page)
  } catch (error) {
    onError(error.toString())
    setPdfPage(null)
  }
}

export function usePdfPage (
  pdfDoc: PDFDocumentProxy | null,
  pageNum: number | null
) {
  const [pdfPage, setPdfPage] = useState<PDFPageProxy | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setError(null)
    if (pdfDoc && pageNum) {
      processPdfPage(pdfDoc, pageNum, setPdfPage, setError)
    } else {
      setPdfPage(null)
    }
  }, [pdfDoc, pageNum])

  return {
    error,
    pdfPage
  }
}

import { makeAutoObservable, flow } from 'mobx'
import * as R from 'ramda'
import { sleep } from 'src/utils/sleep'

export type ErrorType
  = 'FileError'
  | 'PageError'

type OnError = (errorType: ErrorType) => void
export class UiStore {
  isLoadModalOpen = false
  isSaveModalOpen = false
  isExportModalOpen = false
  error: ErrorType | null = null

  private onError!: OnError

  constructor (onError: OnError = R.identity) {
    this.onError = onError
    makeAutoObservable(this, {
      openFileModal: false
    })
  }

  openFileModal = flow(function * openFileModal (this: UiStore) {
    this.isLoadModalOpen = true
    yield sleep()
    this.isLoadModalOpen = false
  }).bind(this)

  openSaveModal = () => {
    this.isSaveModalOpen = true
  }

  openExportModal = () => {
    this.isExportModalOpen = true
  }

  closeSaveModal = () => {
    this.isSaveModalOpen = false
  }

  closeExportModal = () => {
    this.isExportModalOpen = false
  }

  setError = (errorType: ErrorType) => {
    this.error = errorType
    this.onError(errorType)
  }

  clearError = () => {
    this.error = null
  }

  get isAnyModalActive () {
    return R.any(R.equals(true), [
      this.isLoadModalOpen,
      this.isSaveModalOpen,
      this.isExportModalOpen,
      this.error !== null
    ])
  }
}

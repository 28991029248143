import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'

type Props = {
  open: boolean,
  onSave: (filename: string) => void
  onCancel: () => void
}

export function SaveFileDialog ({
  open,
  onSave,
  onCancel
}: Props) {
  const [filename, setFilename] = useState<string>('foo.bar') // TODO: change default filename
  // TODO: disable file drag&drop when this modal is open
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSave(filename)
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      disableBackdropClick
    >
      <DialogTitle>
        Save File
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            value={filename}
            onChange={event => setFilename(event.target.value)}
            autoFocus
            onFocus={event => event.target.select()}
            margin='dense'
            label='Filename'
            type='text'
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button type='submit' color='primary'>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

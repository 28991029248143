import { useState, useEffect, useCallback } from 'react'

export function useLatestJob<Job> (
  execJob: (job: Job) => Promise<void>
) {
  const [isBusy, setBusy] = useState(false)
  const [job, setJob] = useState<Job | null>(null)

  const addJob = useCallback(
    (job: Job) => setJob(job),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    const execJobAndLock = async (job: Job) => {
      setBusy(true)
      setJob(null)
      await execJob(job)
      setBusy(false)
    }

    if (job !== null && !isBusy) {
      execJobAndLock(job)
    }
  }, [execJob, isBusy, job]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    addJob
  }
}

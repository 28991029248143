import React from 'react'
import styled from 'styled-components/macro'
import {
  GeneralOverlayItem,
  // getSize,
  getRotation,
  getCenterHorizontal,
  getCenterVertical
} from './GeneralOverlayItem'

type Props = {
  size: number,
}

const getSize = ({ size }: Props) => size

const borderSize = 2

const Container = styled.div`
  position: absolute;
  font-size: ${getSize}px;
  line-height: 1em;
  z-index: 1;
  border: ${borderSize}px solid #FFF;
  background-color: #FFF;
`

export const MarkLabel: GeneralOverlayItem = (props) => {
  const { text, ...otherProps } = props
  const pos = {
    left: getCenterHorizontal(otherProps),
    top: getCenterVertical(otherProps),
    transform: `translate(-${2 * borderSize}px, -${2 * borderSize}px) rotate(${getRotation(props)}deg)`
  }
  // console.log('pos:', pos)

  return (
    <Container
      size={otherProps.size}
      style={pos}
    >
      {text}
    </Container>
  )
}

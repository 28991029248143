import React, { useRef, ReactNode } from 'react'
import styled from 'styled-components/macro'
import Card from '@material-ui/core/Card'
import ZoomInIcon from '@material-ui/icons/Add'
import ZoomOutIcon from '@material-ui/icons/Remove'
import FitToPageIcon from '@material-ui/icons/FullscreenExit'
import { useSize, useDebounceEffect } from 'ahooks'
import { Size } from 'src/utils/commonTypes'
import { Fab } from './Fab'

type Props = {
  children: ReactNode,
  onZoomIn?: () => void,
  onZoomOut?: () => void,
  onZoomReset?: () => void,
  onSizeChange?: (size: Size) => void
}

const Container = styled(Card)`
  position: relative;
  margin-bottom: ${props => props.theme.spacing(1)}px;
`

const Inner = styled.div`
  /* width: 100%; */
  height: 600px; // TODO: use responsive height
  background-color: whitesmoke;
  position: relative;
  overflow: auto;
  display: flex;
`

const FabArea = styled.div`
  position: absolute;
  right: ${props => props.theme.spacing(4)}px;
  bottom: ${props => props.theme.spacing(4)}px;
  display: flex;
  flex-direction: column;
`

const FabGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing(2)}px;

  & > *  {
    margin-top: ${props => props.theme.spacing(1)}px;
  }
`

export function Viewport ({
  children,
  onZoomIn,
  onZoomOut,
  onZoomReset,
  onSizeChange
}: Props) {
  const ref = useRef(null)
  const size = useSize(ref)

  useDebounceEffect(() => {
    const { width, height } = size
    if (onSizeChange instanceof Function && width && height) {
      onSizeChange({ width, height })
    }
  }, [size])

  return (
    <Container ref={ref} variant='outlined'>
      <Inner>
        {children}
      </Inner>
      <FabArea>
        <FabGroup>
          <Fab
            title='Fit to page'
            onClick={onZoomReset}
          >
            <FitToPageIcon />
          </Fab>
        </FabGroup>
        <FabGroup>
          <Fab
            title='Zoom in'
            onClick={onZoomIn}
          >
            <ZoomInIcon />
          </Fab>
          <Fab
            title='Zoom out'
            onClick={onZoomOut}
          >
            <ZoomOutIcon />
          </Fab>
        </FabGroup>
      </FabArea>
    </Container>
  )
}

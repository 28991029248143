import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { observer } from 'mobx-react-lite'
import { useStore } from '../Store/StoreContext'

const scaleOptions = [
  50,
  75,
  100,
  125,
  150,
  200,
  300,
  400
]

export const SelectScale = observer(function SelectScale () {
  const store = useStore()

  return (
    <Select
      value={store.scale}
      onChange={event => store.setScale(parseInt(event.target.value as string))}
    >
      {
        scaleOptions.map(option => (
          <MenuItem
            key={option}
            value={option}
          >
            {`${option}%`}
          </MenuItem>
        ))
      }
    </Select>
  )
})

import { PDFPageProxy } from 'pdfjs-dist/webpack'

export async function renderToCanvas (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  pdfPage: PDFPageProxy,
  scale: number
) {
  console.log('render page:', pdfPage.pageNumber, `${(scale * 100).toFixed()}%`)

  const viewport = pdfPage.getViewport({ scale })
  const canvasContext = canvasRef.current?.getContext('2d')
  if (canvasContext) {
    const canvasEl = canvasContext.canvas
    canvasEl.width = viewport.width
    canvasEl.height = viewport.height
    canvasEl.style.width = `${viewport.width}px`
    canvasEl.style.height = `${viewport.height}px`
    await pdfPage.render({
      canvasContext,
      viewport
    }).promise
  }
}

import React from 'react'
import { RootStore } from './RootStore.model'

const storeContext = React.createContext<RootStore | null>(null)

export const StoreProvider = storeContext.Provider

export function useStore () {
  const store = React.useContext(storeContext)
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider')
  }
  return store
}

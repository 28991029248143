import { rgb } from 'pdf-lib'
import * as R from 'ramda'

type RGB<Format> = [Format, Format, Format]

export const rgbFromHex = (colorHex: string) => {
  const re = /^#([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})$/
  const match = colorHex.toUpperCase().match(re)
  console.log(`rgbFromHex:: ${colorHex} => ${match}`)

  if (match) {
    const [, ...colors] = match
    const res = R.map(R.pipe(
      (x: string) => parseInt(x, 16),
      (x: number) => x / 0xff
    ))(colors) as RGB<number>

    console.log('rgb:', res)

    return rgb(...res)
  }
}

import React from 'react'
import { useStore } from 'src/features/Store/StoreContext'
import { observer } from 'mobx-react-lite'
import { ViewerContainer } from './ViewerContainer'
import { PdfCanvas } from 'src/features/PdfRenderer'

type Props = {
  children: React.ReactNode
}

export const PdfViewport = observer(function PdfViewport ({
  children
}: Props) {
  const store = useStore()

  return (
    <>
      <ViewerContainer angle={store.document?.currentPage.rotation.angle ?? 0}>
        {
          children
        }
        <PdfCanvas />
      </ViewerContainer>
    </>
  )
})

import styled from 'styled-components/macro'

type Props = {
  angle: number
}

const getAngle = ({ angle = 0 }: Props) => angle

const translateX = ({ angle = 0 }: Props) =>
  [180, 270].includes(angle)
    ? -100
    : 0

const translateY = ({ angle = 0 }: Props) =>
  [90, 180].includes(angle)
    ? -100
    : 0

export const ViewerContainer = styled.div`
  /* display: inline-block;
  position: relative; */
  margin: auto;
  border: 9px solid transparent;
  transform-origin: top left;
  transform: rotate(${getAngle}deg) translate(${translateX}%, ${translateY}%);
`

import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { observer } from 'mobx-react-lite'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import PanToolIcon from '@material-ui/icons/PanTool'

// import { useStore } from 'src/features/Store/StoreContext'
import { AddMarkingButton } from 'src/features/Marks/AddMarkingButton'

const Container = styled(Card)`
  display: flex;
  flex-direction: row;

  & > * {
    margin: ${props => props.theme.spacing(0.5)}px;
  }
`

type Mode = 'edit' | 'pan'

export const EditorMode = observer(function EditorMode () {
  // const store = useStore()
  const [mode, setMode] = useState<Mode>('edit')

  return (
    <Container variant='outlined'>
      <Tooltip title='Panning is coming soon'>
        <div>
          <Button
            variant='contained'
            color={mode === 'pan' ? 'primary' : undefined}
            onClick={() => setMode('pan')}
            disabled
          >
            <PanToolIcon />
          </Button>
        </div>
      </Tooltip>

      <AddMarkingButton
        active={mode === 'edit'}
        onClick={() => setMode('edit')}
      />
    </Container>
  )
})

import { useEffect, useState } from 'react'
import PDFJS, { PDFDocumentProxy } from 'pdfjs-dist/webpack'

export type { PDFDocumentProxy }

type ErrorHandler = (errMsg: string) => void

async function processPdfFile (
  file: string | Uint8Array,
  setPdfDoc: (pdfDoc: PDFDocumentProxy | null) => void,
  onError: ErrorHandler
) {
  try {
    const doc = await PDFJS.getDocument(file).promise
    setPdfDoc(doc)
  } catch (error) {
    onError(error.toString())
    setPdfDoc(null)
  }
}

export function usePdfDoc (
  file: string | Uint8Array | null
) {
  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setError(null)
    if (file) {
      processPdfFile(file, setPdfDoc, setError)
    } else {
      setPdfDoc(null)
    }
  }, [file])

  return {
    error,
    pdfDoc
  }
}
